import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../assets/images/check.svg";
import Header from "../components/Header";

function SignUpSuccess() {
	const { t } = useTranslation();
	return (
		<>
			<div>
				<Header />
				<main className="payment-not-ready">
					<div className="checked-signup-success">
						<Check />
					</div>
					<div className="inset" style={{maxWidth: "100%"}}>
						<h2 style={{fontSize: "4rem", marginBottom: "50px"}}>{t("Please confirm your email!")}</h2>
						<p style={{fontSize: "20px"}}> {t("Check your inbox for an email and click the “Confirm Email” button​")}<br />{t("Don’t forget to check your spam and junk folders as well!")}</p>
					</div>
				</main>
			</div>

		</>
	);
}

export default SignUpSuccess;
