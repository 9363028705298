import React from "react";
import Header from "../components/Header";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

function HomePage() {

	const { t, i18n } = useTranslation();

	return (
		<>
			<Header />

			<div className="content">
				<main className="page-view">
					<h1>{t("Privacy Policy")}</h1>

					<div className="text-content">
						<div>
							
							<div>
								<div>
									<p>
									Esta Política de Privacidade tem como objetivo informar você sobre os dados pessoais que coletamos quando você visita nosso site ou utiliza nossos serviços na EyeTesters.com. Explicamos por que coletamos esses dados e como os tratamos para proteger sua privacidade, em conformidade com a Lei Geral de Proteção de Dados (LGPD) e outras leis brasileiras. Os dados pessoais incluem informações relacionadas ao seu nome, endereço residencial, número de telefone, endereço de e-mail e outras informações de identificação.
									</p>
									<p>
									Leia esta Política de Privacidade com atenção para entender como tratamos seus dados pessoais e garantimos sua proteção. Em caso de dúvidas, entre em contato com nosso Encarregado de Proteção de Dados (DPO) pelo e-mail:  suporte@eyetesters.com.
									</p>
									<h4 >
										<u></u>
										<span>
											1.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
												Dados Pessoais que Coletamos
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
										Dados coletados automaticamente ao acessar nosso site:<u></u>
										<u></u>
									</p>
									<p>
									Quando você acessa nosso site, determinados dados são automaticamente coletados e armazenados em nossos logs de servidor e cookies. Esses dados incluem informações sobre seu histórico de navegação, como páginas acessadas, data e hora das solicitações, URL de referência, consultas de pesquisa e detalhes do dispositivo, como modelo de hardware, versão do sistema operacional, endereço IP, configurações do navegador e idioma.<u></u>
										<u></u>
									</p>
									<p>
									Esses dados são coletados com base em nosso legítimo interesse para melhorar a experiência do usuário e garantir a segurança do site. Você pode configurar seu navegador para bloquear cookies, mas isso pode impactar a usabilidade do site.<u></u>
										<u></u>
									</p>
									<p>
									Dados fornecidos por você ao se cadastrar ou fazer pedidos:<u></u>
										<u></u>
									</p>
									<p>
									Para acessar determinados serviços, é necessário se cadastrar. Solicitamos que forneça dados completos e atualizados para prestação adequada dos serviços. Caso não forneça informações precisas, isso poderá afetar nossa capacidade de atendê-lo.<u></u>
										<u></u>
									</p>
									<p>
									Coletamos as seguintes categorias de dados:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
										Dados de identificação: Nome, telefone, e-mail, ID de cliente, receita médica, entre outros.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Dados financeiros: Informações de pagamento, como dados de cartão de crédito. Não armazenamos detalhes do cartão após o processamento do pedido.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Dados de navegação: Informações relacionadas ao uso do site, incluindo páginas acessadas, URLs de referência e características do dispositivo.<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											2.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Bases Legais para o Tratamento de Dados<u></u>
										<u></u>
									</h4>
									<p>
									Tratamos seus dados pessoais com base nas seguintes bases legais:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
										Execução de contrato: Para fornecer os serviços solicitados.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Cumprimento de obrigação legal: Para cumprir requisitos legais e regulatórios, como retenção de registros financeiros.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Legítimo interesse: Para melhorar nossos serviços e proteger o site contra fraudes.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Consentimento: Quando necessário, solicitamos seu consentimento para usos específicos, como o envio de e-mails promocionais.<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											3.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
											Transferência Internacional de Dados
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
									Os dados pessoais podem ser transferidos para fora do Brasil para processamento em servidores localizados em outros países. Garantimos que essas transferências estão de acordo com a LGPD, utilizando cláusulas contratuais padrão e outras medidas de proteção adequadas.
										<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											4.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
											Retenção de Dados
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
									Os dados pessoais serão mantidos apenas pelo tempo necessário para cumprir as finalidades para as quais foram coletados:
										<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
										Dados de identificação: 7 anos, conforme exigências legais e contratuais.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Dados financeiros: 10 anos, para fins de auditoria e obrigações fiscais.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Dados de navegação: 7 anos, para fins de análise e melhoria dos serviços.<u></u>
											<u></u>
										</li>
									</ul>
									<h4>
										<u></u>
										<span>
											5.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>
										<strong>
											<span>
											Compartilhamento de Dados Pessoais
											</span>
										</strong>
										<u></u>
										<u></u>
									</h4>
									<p>
									Podemos compartilhar seus dados pessoais com terceiros para finalidades específicas:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
										Dados de identificação: Compartilhamento limitado a serviços de envio de SMS (nome e número de telefone).<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Dados financeiros: Não compartilhamos.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Dados de navegação: Compartilhamos para análises e melhorias, sempre de forma anonimizada.<u></u>
											<u></u>
										</li>
									</ul>
									
									<h4>
										<u></u>
										<span>
											6.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Segurança dos Dados Pessoais<u></u>
										<u></u>
									</h4>
									<p>
									Implementamos medidas técnicas e organizacionais adequadas para proteger os dados contra acessos não autorizados, uso indevido e perda. Isso inclui:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
										Controle de acesso restrito aos dados.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Treinamento contínuo dos funcionários.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Monitoramento das práticas de segurança.<u></u>
											<u></u>
										</li>
										
									</ul>
									
									<h4>
										<u></u>
										<span>
											7.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Seus Direitos<u></u>
										<u></u>
									</h4>
									<p>
									Você tem os seguintes direitos garantidos pela LGPD:<u></u>
										<u></u>
									</p>
									<ul type="disc">
										<li className="MsoNormal">
										Acesso, correção e exclusão de seus dados pessoais.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Restrição ou objeção ao processamento.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Solicitação de portabilidade dos dados.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Retirada do consentimento, quando aplicável.<u></u>
											<u></u>
										</li>
										<li className="MsoNormal">
										Apresentação de reclamação à Autoridade Nacional de Proteção de Dados (ANPD).<u></u>
											<u></u>
										</li>
										
									</ul>
									<p>
									Para exercer esses direitos, envie um e-mail para suporte@eyetesters.com com uma cópia do documento de identificação e detalhes do pedido.
									</p>
									<h4>
										<u></u>
										<span>
											8.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Anúncios e Newsletters<u></u>
										<u></u>
									</h4>
									<p>
									Enviaremos notificações de serviço quando necessário, como interrupções temporárias. Caso se inscreva em nossos boletins, poderá cancelar a assinatura a qualquer momento.
										<u></u>
										<u></u>
									</p>
	
									<h4>
										<u></u>
										<span>
											9.
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
										</span>
										<u></u>Opt-In e Opt-Out<u></u>
										<u></u>
									</h4>
									<p>
									Você pode optar por não receber comunicações promocionais a qualquer momento. Se optar por receber, seu consentimento será registrado e poderá ser retirado quando desejar..<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											10.
											<span>&nbsp;</span>
										</span>
										<u></u>Remoção de Anúncios e Cookies
										<u></u>
										<u></u>
									</h4>
									
									<p>
									Para gerenciar cookies, acesse as configurações do navegador. Instruções específicas para diferentes navegadores estão disponíveis nesta política.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											11.
											<span>&nbsp;</span>
										</span>
										<u></u>Notificação de Incidentes de Segurança<u></u>
										<u></u>
									</h4>
									<p>
									Em caso de incidentes de segurança que possam comprometer seus dados pessoais, notificaremos você e a ANPD conforme exigido pela legislação.<u></u>
										<u></u>
									</p>
									<h4>
										<u></u>
										<span>
											12.
											<span>&nbsp;</span>
										</span>
										<u></u>Alterações nesta Política de Privacidade<u></u>
										<u></u>
									</h4>
									<p>
									Revisamos regularmente esta política para mantê-la em conformidade com as leis vigentes. Quaisquer mudanças serão publicadas em nosso site.<u></u>
										<u></u>
									</p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			<Footer />
		</>
	);
}

export default HomePage;
