import { useTranslation } from "react-i18next";
import { QR_DOMAIN } from "./utils/constants";

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <footer>
        <div class="content">
          <div class="logo">
            <img src="images/logo.png" alt="" />
          </div>
          <nav>
            {/* <a href={`${QR_DOMAIN}#`}>{t("Terms of Use")}</a> */}
            {i18n.language === "en" ? (<a href="privacy-policy">{t("Privacy Policy")}</a> ) : (<a href="privacy-policy-br">{t("Privacy Policy")}</a>)}
            
          </nav>

          <div class="options">
            <span>© {t("All rights reserved")}.</span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
