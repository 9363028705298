// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qr-svg";
import { Button, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { URL_ROUTE_USER } from "../utils/constants";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import Subscribe from "../components/Subscribe";
import SubscribeTrial from "../components/SubscribeTrial";

import {
	FunctionAddPower,
	FunctionEyeValence,
	SPEMethods,
	FunctionMeasuredRx,
	FunctionWeightedRx,
	FunctionProcessedFinalRx,
} from "./AJmath";

function Page18Thankyou() {
	const { t, i18n } = useTranslation();

	const [state, setState] = useState({
		status: "loading",
	});
	const [user_info_logged, setUser_info_logged] = useState();

	const Formula0 = () => {
		//RUN EVERYTHING
		//Formula2()
		Formula3();
		Formula4();
		Formula5();
		Formula6();
		Formula7();
		// FunctionProcessedFinalRx()
		Formula1();
	};
	const Formula1 = () => {
		// Send All Possible patient data to console to see whats present/missing
		//INTAKE FORM DATA
		let age = localStorage.getItem("age");
		let dob = localStorage.getItem("dob");
		let name = localStorage.getItem("name") || user_info_logged?.name;
		let phone = localStorage.getItem("phone");
		let email = localStorage.getItem("email") || user_info_logged?.email;
		let gender = localStorage.getItem("gender");
		let KnowSpec = localStorage.getItem("KnowSpec");
		let HaveSpec = localStorage.getItem("HaveSpec");
		let KnowContact = localStorage.getItem("KnowContact");
		let CL_OldBrandOD = localStorage.getItem("CL_OldBrandOD");
		let CL_OldBrandOS = localStorage.getItem("CL_OldBrandOS");
		let ratio = localStorage.getItem("ratio");
		let OldSphOD = localStorage.getItem("OldSphOD");
		let OldSphOS = localStorage.getItem("OldSphOS");
		let OldCylOD = localStorage.getItem("OldCylOD");
		let OldCylOS = localStorage.getItem("OldCylOS");
		let OldAxisOD = localStorage.getItem("OldAxisOD");
		let OldAxisOS = localStorage.getItem("OldAxisOS");
		let CL_OldSphOD = localStorage.getItem("CL_OldSphOD");
		let CL_OldCylOD = localStorage.getItem("CL_OldCylOD");
		let CL_OldAxisOD = localStorage.getItem("CL_OldAxisOD");
		let CL_OldBaseCurveOD = localStorage.getItem("CL_OldBaseCurveOD");
		let CL_OldDiamOD = localStorage.getItem("CL_OldDiamOD");
		let CL_OldSphOS = localStorage.getItem("CL_OldSphOS");
		let CL_OldCylOS = localStorage.getItem("CL_OldCylOS");
		let CL_OldAxisOS = localStorage.getItem("CL_OldAxisOS");
		let CL_OldBaseCurveOS = localStorage.getItem("CL_OldBaseCurveOS");
		let CL_OldDiamOS = localStorage.getItem("CL_OldDiamOS");
		let NumberOfSteps = localStorage.getItem("NumberOfSteps");

		//DATA from the actual testing
		let Answer1a = localStorage.getItem("Answer1a");
		let Answer1b = localStorage.getItem("Answer1b");
		let VAccOD = localStorage.getItem("VAccOD");
		let VAccOS = localStorage.getItem("VAccOS");
		let VAscOD = localStorage.getItem("VAscOD");
		let VAscOS = localStorage.getItem("VAscOS");
		let PrimeDrPOVOD = localStorage.getItem("PrimeDrPOVOD");
		let PrimeDrPOVOS = localStorage.getItem("PrimeDrPOVOS");
		let VAaOD = localStorage.getItem("VAaOD");
		let VAaOS = localStorage.getItem("VAaOS");

		//DATA that was derived from math
		let Add = localStorage.getItem("Add");
		let EyeValenceOD = localStorage.getItem("EyeValenceOD");
		let EyeValenceOS = localStorage.getItem("EyeValenceOS");
		let EyeValenceSafety = localStorage.getItem("EyeValenceSafety");
		let SPEscOD = localStorage.getItem("SPEscOD");
		let SPEscOS = localStorage.getItem("SPEscOS");
		let SPEaOD = localStorage.getItem("SPEaOD");
		let SPEaOS = localStorage.getItem("SPEaOS");
		let MeasuredSphOD = localStorage.getItem("MeasuredSphOD");
		let MeasuredSphOS = localStorage.getItem("MeasuredSphOS");
		let MeasuredCylOD = localStorage.getItem("MeasuredCylOD");
		let MeasuredCylOS = localStorage.getItem("MeasuredCylOS");
		let MeasuredAxisOD = localStorage.getItem("MeasuredAxisOD");
		let MeasuredAxisOS = localStorage.getItem("MeasuredAxisOS");
		let FinalSphOD = localStorage.getItem("FinalSphOD");
		let FinalCylOD = localStorage.getItem("FinalCylOD");
		let FinalAxisOD = localStorage.getItem("FinalAxisOD");
		let FinalSphOS = localStorage.getItem("FinalSphOS");
		let FinalCylOS = localStorage.getItem("FinalCylOS");
		let FinalAxisOS = localStorage.getItem("FinalAxisOS");

		if (FinalAxisOD == 0) {
			FinalAxisOD = "180";
		}
		if (FinalAxisOS == 0) {
			FinalAxisOS = "180";
		}

		let FinalCLAxisOD = Math.round(FinalAxisOD / 10) * 10 || "no axis"; //rounds the contact lens Axis to the nearest 10 degrees
		let FinalCLAxisOS = Math.round(FinalAxisOS / 10) * 10 || "no axis";

		const Ptdata = {
			age: age,
			dob: dob,
			name: name,
			email: email,
			phone: phone,
			gender: gender,
			KnowSpec: KnowSpec,
			HaveSpec: HaveSpec,
			KnowContact: KnowContact,
			CL_OldBrandOD: CL_OldBrandOD,
			CL_OldBrandOS: CL_OldBrandOS,
			ratio: ratio,
			OldSphOD: OldSphOD,
			OldSphOS: OldSphOS,
			OldCylOD: OldCylOD,
			OldCylOS: OldCylOS,
			OldAxisOD: OldAxisOD,
			OldAxisOS: OldAxisOS,
			CL_OldSphOD: CL_OldSphOD,
			CL_OldCylOD: CL_OldCylOD,
			CL_OldAxisOD: CL_OldAxisOD,
			CL_OldBaseCurveOD: CL_OldBaseCurveOD,
			CL_OldDiamOD: CL_OldDiamOD,
			CL_OldSphOS: CL_OldSphOS,
			CL_OldCylOS: CL_OldCylOS,
			CL_OldAxisOS: CL_OldAxisOS,
			CL_OldBaseCurveOS: CL_OldBaseCurveOS,
			CL_OldDiamOS: CL_OldDiamOS,
			NumberOfSteps: NumberOfSteps,

			Answer1a: Answer1a,
			Answer1b: Answer1b,
			VAccOD: VAccOD,
			VAccOS: VAccOS,
			VAscOD: VAscOD,
			VAscOS: VAscOS,
			PrimeDrPOVOD: PrimeDrPOVOD,
			PrimeDrPOVOS: PrimeDrPOVOS,
			VAaOD: VAaOD,
			VAaOS: VAaOS,

			Add: Add,
			EyeValenceOD: EyeValenceOD,
			EyeValenceOS: EyeValenceOS,
			EyeValenceSafety: EyeValenceSafety,
			SPEscOD: SPEscOD,
			SPEscOS: SPEscOS,
			SPEaOD: SPEaOD,
			SPEaOS: SPEaOS,
			MeasuredSphOD: MeasuredSphOD,
			MeasuredSphOS: MeasuredSphOS,
			MeasuredCylOD: MeasuredCylOD,
			MeasuredCylOS: MeasuredCylOS,
			MeasuredAxisOD: MeasuredAxisOD,
			MeasuredAxisOS: MeasuredAxisOS,
			FinalSphOD: FinalSphOD,
			FinalCylOD: FinalCylOD,
			FinalAxisOD: String(FinalAxisOD),
			FinalCLSphOD: FinalSphOD,
			FinalCLCylOD: FinalCylOD,
			FinalCLAxisOD: String(FinalCLAxisOD),
			FinalSphOS: FinalSphOS,
			FinalCylOS: FinalCylOS,
			FinalAxisOS: FinalAxisOS,
			FinalCLSphOS: FinalSphOS,
			FinalCLCylOS: FinalCylOS,
			FinalCLAxisOS: String(FinalCLAxisOS),
			text_title: t("text_title"),
			text_subhead: t("text_subhead"),
			text_glasses: t("Glasses"),
			text_right_eye: t("Right Eye"),
			text_left_eye: t("Left Eye"),
			text_contacts: t("Contacts"),
			text_demo: t("text_demo"),
			text_sphere: t("FINAL RX SPHERE"),
			text_cylinder: t("Cylinder"),
			text_axis: t("AXIS"),
			text_power: t("Add Power"),
			text_subject: t("text_subject"),
			text_feedback_button: t("text_feedback_button"),
			text_feedback_text: t("text_feedback_text"),
		};
		console.log(Ptdata);

		return Ptdata;
	};
	// const Formula2 = () => { // Use this Forumla to Write Fake Data to Console
	//         localStorage.setItem('age', 28)
	//         localStorage.setItem('dob', "1994-04-08")
	//         localStorage.setItem('gender', "male")
	//         localStorage.setItem('KnowSpec', "yes")
	//         localStorage.setItem('HaveSpec', "yes")
	//         localStorage.setItem('KnowContact', "yes")
	//         localStorage.setItem('CL_OldBrandOD', "1-Day Acuvue Moist for Astigmatism")
	//         localStorage.setItem('CL_OldBrandOS', "2-Day Acuvue Moist for Astigmatism")
	//         localStorage.setItem('ratio', 27.79928,)
	//         localStorage.setItem('OldSphOD', -2.5)
	//         localStorage.setItem('OldSphOS', -1)
	//         localStorage.setItem('OldCylOD', -0.5)
	//         localStorage.setItem('OldCylOS', -0.75)
	//         localStorage.setItem('OldAxisOD', 180)
	//         localStorage.setItem('OldAxisOS', 45)
	//         localStorage.setItem('CL_OldSphOD', -2.5)
	//         localStorage.setItem('CL_OldCylOD', -1)
	//         localStorage.setItem('CL_OldAxisOD', 180)
	//         localStorage.setItem('CL_OldBaseCurveOD', 8.2)
	//         localStorage.setItem('CL_OldDiamOD', 14)
	//         localStorage.setItem('CL_OldSphOS', -1)
	//         localStorage.setItem('CL_OldCylOS', -0.5)
	//         localStorage.setItem('CL_OldAxisOS', 45)
	//         localStorage.setItem('CL_OldBaseCurveOS', 8.2)
	//         localStorage.setItem('CL_OldDiamOS', 14)
	//         localStorage.setItem('NumberOfSteps', 11)

	//         localStorage.setItem('Answer1a', "near")
	//         localStorage.setItem('Answer1b', "near")
	//         localStorage.setItem('VAccOD', 25)
	//         localStorage.setItem('VAccOS', 20)

	//         localStorage.setItem('VAscOD', 200)
	//         localStorage.setItem('VAscOS', 200)
	//         localStorage.setItem('PrimeDrPOVOD', 180)
	//         localStorage.setItem('PrimeDrPOVOS', 45)
	//         localStorage.setItem('VAaOD', 150)
	//         localStorage.setItem('VAaOS', 180)

	// };
	const Formula3 = () => {
		//ADD POWER
		let gender = localStorage.getItem("gender");
		let age = localStorage.getItem("age");

		let AddPwr = FunctionAddPower(gender, age);

		localStorage.setItem("Add", AddPwr);
	};
	const Formula4 = () => {
		//Eye VALENCE

		let KnowSpec = localStorage.getItem("KnowSpec");
		let KnowContact = localStorage.getItem("KnowContact");
		let OldSphOD = localStorage.getItem("OldSphOD");
		let OldSphOS = localStorage.getItem("OldSphOS");
		let OldCylOD = localStorage.getItem("OldCylOD");
		let OldCylOS = localStorage.getItem("OldCylOS");
		let Answer1a = localStorage.getItem("Answer1a");
		let Answer1b = localStorage.getItem("Answer1b");
		let CL_OldSphOD = localStorage.getItem("CL_OldSphOD");
		let CL_OldCylOD = localStorage.getItem("CL_OldCylOD");
		let CL_OldSphOS = localStorage.getItem("CL_OldSphOS");
		let CL_OldCylOS = localStorage.getItem("CL_OldCylOS");

		let EyeValenceOD = FunctionEyeValence(
			KnowSpec,
			KnowContact,
			OldSphOD,
			OldSphOS,
			OldCylOD,
			OldCylOS,
			Answer1a,
			Answer1b,
			CL_OldSphOD,
			CL_OldSphOS,
			CL_OldCylOD,
			CL_OldCylOS
		).EyeValenceOD;
		let EyeValenceOS = FunctionEyeValence(
			KnowSpec,
			KnowContact,
			OldSphOD,
			OldSphOS,
			OldCylOD,
			OldCylOS,
			Answer1a,
			Answer1b,
			CL_OldSphOD,
			CL_OldSphOS,
			CL_OldCylOD,
			CL_OldCylOS
		).EyeValenceOS;
		let EyeValenceSafety = FunctionEyeValence(
			KnowSpec,
			KnowContact,
			OldSphOD,
			OldSphOS,
			OldCylOD,
			OldCylOS,
			Answer1a,
			Answer1b,
			CL_OldSphOD,
			CL_OldSphOS,
			CL_OldCylOD,
			CL_OldCylOS
		).EyeValenceSafety;

		localStorage.setItem("EyeValenceOD", EyeValenceOD);
		localStorage.setItem("EyeValenceOS", EyeValenceOS);
		localStorage.setItem("EyeValenceSafety", EyeValenceSafety);
	};
	const Formula5 = () => {
		// SPE

		let eyeValenceOD = localStorage.getItem("EyeValenceOD");
		let eyeValenceOS = localStorage.getItem("EyeValenceOS");
		var VAscOD = localStorage.getItem("VAscOD");
		let VAscOS = localStorage.getItem("VAscOS");
		let VAaOD = localStorage.getItem("VAaOD");
		let VAaOS = localStorage.getItem("VAaOS");
		let Age = localStorage.getItem("age");

		let SPEscOD = SPEMethods(eyeValenceOD, VAscOD, Age);
		let SPEscOS = SPEMethods(eyeValenceOS, VAscOS, Age);
		let SPEaOD = SPEMethods(eyeValenceOD, VAaOD, Age);
		let SPEaOS = SPEMethods(eyeValenceOS, VAaOS, Age);

		localStorage.setItem("SPEscOD", SPEscOD);
		localStorage.setItem("SPEscOS", SPEscOS);
		localStorage.setItem("SPEaOD", SPEaOD);
		localStorage.setItem("SPEaOS", SPEaOS);
	};
	const Formula6 = () => {
		//MEASURED Rx

		let EyeValenceOD = localStorage.getItem("EyeValenceOD");
		let EyeValenceOS = localStorage.getItem("EyeValenceOS");
		let SPEscOD = localStorage.getItem("SPEscOD");
		let SPEscOS = localStorage.getItem("SPEscOS");
		let SPEaOD = localStorage.getItem("SPEaOD");
		let SPEaOS = localStorage.getItem("SPEaOS");
		let PrimeDrPOVOD = localStorage.getItem("PrimeDrPOVOD");
		let PrimeDrPOVOS = localStorage.getItem("PrimeDrPOVOS");

		let measuredRxOD = FunctionMeasuredRx(
			SPEscOD,
			SPEaOD,
			PrimeDrPOVOD,
			EyeValenceOD
		);
		let measuredRxOS = FunctionMeasuredRx(
			SPEscOS,
			SPEaOS,
			PrimeDrPOVOS,
			EyeValenceOS
		);

		localStorage.setItem(
			"MeasuredSphOD",
			(Math.round(measuredRxOD.Sphere * 4) / 4).toFixed(2)
		);
		localStorage.setItem(
			"MeasuredSphOS",
			(Math.round(measuredRxOS.Sphere * 4) / 4).toFixed(2)
		);
		localStorage.setItem(
			"MeasuredCylOD",
			(Math.round(measuredRxOD.Cylinder * 4) / 4).toFixed(2)
		);
		localStorage.setItem(
			"MeasuredCylOS",
			(Math.round(measuredRxOS.Cylinder * 4) / 4).toFixed(2)
		);
		localStorage.setItem("MeasuredAxisOD", measuredRxOD.Axis);
		localStorage.setItem("MeasuredAxisOS", measuredRxOS.Axis);
	};
	const Formula7 = () => {
		//Weighted FinalRx
		let VAccOD = localStorage.getItem("VAccOD");
		let VAccOS = localStorage.getItem("VAccOS");
		let OldSphOD = localStorage.getItem("OldSphOD");
		let OldSphOS = localStorage.getItem("OldSphOS");
		let OldCylOD = localStorage.getItem("OldCylOD");
		let OldCylOS = localStorage.getItem("OldCylOS");
		let OldAxisOD = localStorage.getItem("OldAxisOD");
		let OldAxisOS = localStorage.getItem("OldAxisOS");

		let MeasuredSphOD = localStorage.getItem("MeasuredSphOD");
		let MeasuredSphOS = localStorage.getItem("MeasuredSphOS");
		let MeasuredCylOD = localStorage.getItem("MeasuredCylOD");
		let MeasuredCylOS = localStorage.getItem("MeasuredCylOS");
		let MeasuredAxisOD = localStorage.getItem("MeasuredAxisOD");
		let MeasuredAxisOS = localStorage.getItem("MeasuredAxisOS");

		let FinalRxOD = FunctionWeightedRx(
			VAccOD,
			OldSphOD,
			OldCylOD,
			OldAxisOD,
			MeasuredSphOD,
			MeasuredCylOD,
			MeasuredAxisOD
		);
		let FinalRxOS = FunctionWeightedRx(
			VAccOS,
			OldSphOS,
			OldCylOS,
			OldAxisOS,
			MeasuredSphOS,
			MeasuredCylOS,
			MeasuredAxisOS
		);

		localStorage.setItem("FinalSphOD", FinalRxOD.FinalSph);
		localStorage.setItem("FinalCylOD", FinalRxOD.FinalCyl);
		localStorage.setItem("FinalAxisOD", FinalRxOD.FinalAxis);
		localStorage.setItem("FinalSphOS", FinalRxOS.FinalSph);
		localStorage.setItem("FinalCylOS", FinalRxOS.FinalCyl);
		localStorage.setItem("FinalAxisOS", FinalRxOS.FinalAxis);
	};

	const SafetyDetermination = () => {
		let Safety = "pass";

		if (Ptdata.EyeValenceSafety === "fail") {
			Safety = t('fail due to EyeValence being "fail"');
		} else if (Ptdata.EyeValenceOD !== Ptdata.EyeValenceOS) {
			Safety = t("fail due to EyeValenceOD not equal to EyeValence OS");
		} else if (Ptdata.age < 15 || Ptdata.age > 55) {
			Safety = t("fail due to patients age not being between 15 and 55");
		} else if (
			Ptdata.FinalSphOD < -3 ||
			Ptdata.FinalSphOD > 3 ||
			Ptdata.FinalSphOS < -3 ||
			Ptdata.FinalSphOS > 3
		) {
			Safety = t("fail due to Final Sphere being greater that 3 Diopters");
		} else if (
			Math.abs(Ptdata.FinalCylOD) > 2.5 * Math.abs(Ptdata.FinalSphOD) ||
			Math.abs(Ptdata.FinalCylOS) > 2.5 * Math.abs(Ptdata.FinalSphOS)
		) {
			Safety = t(
				"fail since the Final Cyl (abs value) is bigger than the final Sphere"
			);
		} else if (Ptdata.FinalCylOD > 0 || Ptdata.FinalCylOS > 0) {
			Safety = t("fail since the Final Cyl was going to be positive");
		}
		// else if(KnowSpec === "yes" && HaveSpec === "yes"){
		//     if (Math.abs(OldAxisOD - MeasuredAxisOD >40) || Math.abs(OldAxisOS - MeasuredAxisOS) >40)
		//     Safety = 'fail since the difference between the old axis and the measured axis was too high'
		// }

		return Safety;
	};

	const getUserLogged = async () => {
		// Get user info logged in
		await axios.get(`${process.env.REACT_APP_API}/user/user-info`, { withCredentials: true })
			.then(function (res) {
				if (res.data.result) {
					setUser_info_logged(res.data.user);
					localStorage.setItem("email", res.data.user.email);
					localStorage.setItem("name", res.data.user.name);
				}
			})
	}

	useEffect(() => {
		sendToServer();
	}, []);

	const sendToServer = async () => {
		await getUserLogged();
		let Ptdata = Formula1();
		let uuid = localStorage.getItem("last_uuid");
		let log_uuid = localStorage.getItem("log_uuid");
		// return;
		axios
			.post(
				`${process.env.REACT_APP_API}/user/save`,
				{
					...Ptdata,
					uuid,
				},
				{
					withCredentials: true,
				}
			)
			.then((res) => {
				if (res.data.result && res.data.uuid) {
					localStorage.setItem("last_uuid", res.data.uuid);
					axios.post(`${process.env.REACT_APP_API}/user/updateTestPrescriptions/${log_uuid}`, { uuid: res.data.uuid } , { withCredentials: true }).catch((e) => console.log('error -->>', e));

					axios
						.get(`${process.env.REACT_APP_API}/user/check-payment`, {
							withCredentials: true,
						})
						.then((res) => {
							if (res.data.result) {
								setState({
									status: "show",
								});
							} else {
								setState({
									status: "subscription",
								});
							}
						})
						.catch((e) => {
							setState({
								status: "error",
							});
						});
				}
			})
			.catch((e) => {
				setState({
					status: "error",
				});
			});

		// const response = await fetch(`${URL_ROUTE_USER}/save`, {
		// 	method: "POST",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// 	body: JSON.stringify(Ptdata),
		// });

		// let res = await response.json();

		//console.log(res);
	};

	Formula0();
	let Ptdata = Formula1();

	let Message1 = "Right Eye";
	let Message2 =
		"FINAL RX SPHERE: " +
		Ptdata.FinalSphOD +
		", CYLINDER: " +
		Ptdata.FinalCylOD +
		", AXIS: " +
		Ptdata.FinalAxisOD;
	let Message2b =
		"MSRD RX SPHERE: " +
		Ptdata.MeasuredSphOD +
		", CYLINDER: " +
		Ptdata.MeasuredCylOD +
		", AXIS: " +
		Ptdata.MeasuredAxisOD +
		" VAsc: " +
		Ptdata.VAscOD +
		"(" +
		(Math.round(Ptdata.SPEscOD * 4) / 4).toFixed(2) +
		")" +
		" VAa: " +
		Ptdata.VAaOD;
	let Message2c =
		" OLD RX SPHERE:  " +
		Ptdata.OldSphOD +
		", CYLINDER: " +
		Ptdata.OldCylOD +
		", AXIS: " +
		Ptdata.OldAxisOD +
		" VAcc: 20/" +
		Ptdata.VAccOD +
		"(" +
		(Number(Ptdata.OldSphOD) + Ptdata.OldCylOD / 2) +
		")";

	let Message3 = "Left Eye";
	let Message4 =
		"FINAL RX SPHERE:  " +
		Ptdata.FinalSphOS +
		", CYLINDER: " +
		Ptdata.FinalCylOS +
		", AXIS: " +
		Ptdata.FinalAxisOS;
	let Message4b =
		"MSRD RX SPHERE: " +
		Ptdata.MeasuredSphOS +
		", CYLINDER: " +
		Ptdata.MeasuredCylOS +
		", AXIS: " +
		Ptdata.MeasuredAxisOS +
		" VAsc: " +
		Ptdata.VAscOS +
		"(" +
		(Math.round(Ptdata.SPEscOS * 4) / 4).toFixed(2) +
		")" +
		" VAa: " +
		Ptdata.VAaOS;
	let Message4c =
		" OLD RX SPHERE:  " +
		Ptdata.OldSphOS +
		", CYLINDER: " +
		Ptdata.OldCylOS +
		", AXIS: " +
		Ptdata.OldAxisOS +
		" VAcc: 20/" +
		Ptdata.VAccOS +
		"(" +
		(Number(Ptdata.OldSphOS) + Ptdata.OldCylOS / 2) +
		")";

	let Message4a = "Add Power: +" + Ptdata.Add;

	let Message5 = "Contacts:";
	let Message6 =
		Ptdata.FinalSphOD +
		t("CYL") +
		": " +
		Ptdata.FinalCylOD +
		" " +
		t("AXIS") +
		": " +
		Ptdata.FinalCLAxisOD +
		" " +
		t("BRAND") +
		": " +
		Ptdata.CL_OldBrandOD +
		" " +
		t("BC") +
		": " +
		Ptdata.CL_OldBaseCurveOD +
		" " +
		t(" DIAM") +
		": " +
		Ptdata.CL_OldDiamOD;
	let Message7 =
		Ptdata.FinalSphOS +
		t("CYL") +
		": " +
		Ptdata.FinalCylOS +
		" " +
		t("AXIS") +
		": " +
		Ptdata.FinalCLAxisOS +
		" " +
		t("BRAND") +
		": " +
		Ptdata.CL_OldBrandOS +
		" " +
		t("BC") +
		": " +
		Ptdata.CL_OldBaseCurveOS +
		" " +
		t("DIAM") +
		":" +
		Ptdata.CL_OldDiamOS;

	let Safety = SafetyDetermination();

	console.log(state);

	if (state.status === "loading") {
		return (
			<main className="loading">
				<Spinner />
			</main>
		);
	}

	if (state.status === "subscription") {
		return (
			<div className="thank-you-page">
				<div className="content">
					<SubscribeTrial />
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="thank-you-page">
				<div className="subscription-view-notice">
					<h2>{t("Test completed successfully.")}</h2>
					<p>{t("Please hold on for a moment, our specialists are reviewing your prescription.")}</p>
				</div>
			</div>
		</>
	);
}

export default withRouter(Page18Thankyou);
