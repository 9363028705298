//import './App.css';
import React, { Suspense, useRef, useState, useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import LoginLogo from "../assets/images/login-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useLocation, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { useTranslation } from "react-i18next";

function App(props) {

	const { t } = useTranslation();

	const captchaRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [remember, setRemember] = useState(false);
	const [state, setState] = useState({});
	const history = useHistory();
	const location = useLocation();
	const RECAPTCHA_SECRET=process.env.REACT_APP_RECAPTCHA_SECRET;
	const login = () => {
		if (remember) {
			localStorage.setItem("eyetesters_email", state.email);
		}

		if (!state.email)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid e-mail"),
			});
		if (!state.password)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid password"),
			});

		if (!state.token)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, check on captcha verification"),
			});

		setLoading(true);

		axios
			.post(`${process.env.REACT_APP_API}/user/login`, state, {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.result) {
					// if (location?.state?.from) {
						// history.push(location.state?.from);
					// 	window.location.href = location.state?.from;
					// } else {
						// history.push("/");
						window.location.href = "/";
					// }
				} else {
					setLoading(false);
					let error = t("Could not login at this time. Please, try again later.");
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				let error = t("Could not login at this time. Please, try again later.");
				if (e.response?.status === 400 && e.response?.data?.error) {
					error = t(e.response.data.error);
				}
				Swal.fire({
					icon: "error",
					title: "Ooopsss!",
					text: error,
				});
			})
			captchaRef.current.reset();
	};

	useEffect(() => {
		if (localStorage.getItem("eyetesters_email")) {
			setState((state) => {
				return {
					...state,
					email: localStorage.getItem("eyetesters_email"),
				};
			});
		}
	}, []);

	return (
		<>
			<main className="login-wrapper">
				<div className="logo">
					<img src={LoginLogo} alt="" />
				</div>
				<div className="form">
					<div className="form-wrapper">
						<div className="form-inset">
							<h2>Login</h2>
							<span>{t("To get started, please, fill the form below")}</span>

							<div className="form-group">
								<label>{t("Your e-mail")}</label>
								<input
									type="text"
									value={state?.email || null}
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												email: value,
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>{t("Your password")}</label>
								<input
									type="password"
									onChange={(e) => {
										setState((state) => {
											return {
												...state,
												password: e.target.value,
											};
										});
									}}
								/>
							</div>

							<div className="recatpcha" style={{display: "flex", }}>
								<ReCAPTCHA
									sitekey={RECAPTCHA_SECRET}
									ref={captchaRef}
									onChange={(token) => {
										setState((state) => {
											return {
												...state,
												token,
											};
										});
									}}
								/>
								<div className="forgotten-password" style={{height: "20px", marginLeft: "-5px", width: "150px"}}>
								 <Link to="/forgotten" style={{width: "150px", display: "block", color: "#216e75"}}>{t("Forgot password?")}</Link>
								</div>
							</div>

							<div className="form-options">
								<div className="check">
									<input
										type="checkbox"
										name=""
										id=""
										onChange={(e) => {
											setRemember(e.target.checked);
										}}
									/>
									{t("Remember me")}
								</div>

								{!loading ? (
									<button onClick={login}>{t("Continue")}</button>
								) : (
									<Spinner />
								)}
							</div>


							<div className="not-registered">
								{t("Or get started by")} <Link to="/signup">{t("signing up")}</Link>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

export default App;
