import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Header from "../components/Header";
import ErrorView from "../components/Error";
import Footer from "../Footer";
import axios from "axios";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import dayjs from "dayjs";
import Subscribe from "../components/Subscribe";


function HomePage() {
	let history = useHistory();
	const { t, i18n } = useTranslation();

	const [status, setStatus] = useState("loading");
	const [state, setState] = useState(null);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API}/user/subscription?lng=br`, {
				withCredentials: true,
			})
			.then(function (res) {
				console.log(res);
				if (res.data.result) {
					setState(res.data.subscription);
					setStatus("done");
				} else {
					setStatus("nosubscription");
				}
			})
			.catch((e) => {
				setStatus("error");
			});
	}, []);

	const renderStatus = (status) => {
		if (status === "active") return <div className="badge success">{t("Active")}</div>;
		if (status === "paid") return <div className="badge success">{t("Paid")}</div>;
		if (status === "trialing") return <div className="badge bg-warning">{t("trialing")}</div>;
	};

	const renderValue = (value, currency) => {
		value = value.toString();
		let cents = value.slice(value.length - 2, value.length);
		let total = value.slice(0, value.length - 2);
		console.log(cents);
		let val = parseFloat(`${total}.${cents}`);

		const listCurrencyCountry = {
			"usd": { locale: "en-US", currency: "USD" },
			"brl": { locale: "pt-BR", currency: "BRL" }
		}

		const currentCurrency = listCurrencyCountry[currency] || listCurrencyCountry["usd"];
		
		// val = val.toLocaleString("en-US", { style: "currency", currency: "USD" });
		val = val.toLocaleString(currentCurrency.locale, { style: "currency", currency: currentCurrency.currency });
		return val;
	};

	const manageBilling = () => {
		axios
			.get(`${process.env.REACT_APP_API}/user/manage-billing`, {
				withCredentials: true,
			})
			.then((res) => {
				if (res?.data?.result) {
					window.location.href = res.data.url;
				} else {
					let error =
						t("Could not process at this time. Please, try again later.");
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error,
					});
				}
			})
			.catch((e) => {
				let error = t("Could not process at this time. Please, try again later.");
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error,
				});
			});
	};

	const cancel = () => {
		Swal.fire({
			title: t("Cancel subscription"),
			text: t("Are you sure you want to cancel your subscription?"),
			icon: "warning",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.isConfirmed) {
				setState((s) => {
					return {
						...s,
						status: "loading",
					};
				});

				axios
					.post(
						`${process.env.REACT_APP_API}/user/cancel-subscription`,
						{},
						{
							withCredentials: true,
						}
					)
					.then((res) => {
						if (res?.data?.result) {
							window.location.reload();
						} else {
							let error =
								t("Could not process at this time. Please, try again later.");
							Swal.fire({
								icon: "error",
								title: "Error",
								text: error,
							});
						}
					})
					.catch((e) => {
						let error =
							t("Could not process at this time. Please, try again later.");
						Swal.fire({
							icon: "error",
							title: "Error",
							text: error,
						});
					});
			}
		});
	};

	const pay = () => {
		setState((s) => {
			return {
				...s,
				status: "loading",
			};
		});

		axios
			.post(
				`${process.env.REACT_APP_API}/user/create-payment-session?lng=${i18n.language || "en"}`,
				{},
				{
					withCredentials: true,
				}
			)
			.then((res) => {
				if (res?.data?.result) {
					window.location.href = res.data.url;
				}
			})
			.catch((e) => {
				let error = t("Could not process at this time. Please, try again later.");
				if (e.response.status === 400 && e.response?.data?.error) {
					error = e.response.data.error;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error,
				});
			});
	};

	return (
		<>
			<Header />

			<div className="content">
				<main className="user-page">
					<h1>{t("My Subscription")}</h1>

					{status === "loading" ? (
						<div className="loading-view auto">
							<div className="spinner-wrapper">
								<Spinner />
							</div>
						</div>
					) : null}

					{status === "nosubscription" ? (
						<div style={{margin: '40px 0'}}>
							<Subscribe />
						</div>
					) : null}

					{status === "done" && state ? (
						<div className="subscription-view">
							<div className="subscription-status">
								<div className="col">
									<span>{t("Status")}</span>
									{renderStatus(state.status)}
								</div>
								<div className="col">
									<span>{t("Expires at")}</span>
									<strong>
										{state.status !== "trialing" ? dayjs(state.current_period_end * 1000).format(
											"MM/DD/YYYY"
										) : "-"}
									</strong>
								</div>
								<div className="col">
									<span>{t("Total")}</span>
									<strong>{renderValue(state.status === "trialing" ? 0 : state.amount_total, state.currency)}</strong>
								</div>
								<div className="col">
									{state?.status !== "loading" ? (
										<div className="subscription-options">
											{state?.status !== "trialing" ? (<button className="button" onClick={manageBilling}>
												{t("Manage Subscription & Billing")}
											</button>
											) : null}
										</div>
									) : (
										<div style={{ textAlign: "center", width: "100%" }}>
											<Spinner />
										</div>
									)}
								</div>
							</div>

							{state?.invoices?.length ? (
								<div className="payments">
									<h2>{t("Your invoices")}</h2>

									<table>
										<thead>
											<tr>
												<th>{t("Date")}</th>
												<th>{t("Total")}</th>
												<th>{t("Gateway")}</th>
												<th>{t("Status")}</th>
												<th>{t("Action")}</th>
											</tr>
										</thead>
										<tbody>
											{state.invoices.map((item, index) => {
												return (
													<tr>
														<td>
															{dayjs(item.created * 1000).format("MM/DD/YYYY")}
														</td>
														<td>{renderValue(item.amount, state.currency)}</td>
														<td>{item.gateway_name}</td>
														<td>{renderStatus(item.status)}</td>
														<td>
															<a href={item.invoice_pdf}>{t("Download")}</a>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							) : (
								<div></div>
							)}
						</div>
					) : null}
					{status === "error" && <ErrorView />}
				</main>
			</div>
			<Footer />
		</>
	);
}

export default HomePage;
