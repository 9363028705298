import React, { useRef, useState } from "react";
import LoginLogo from "../assets/images/login-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import { useTranslation } from "react-i18next";

function App() {

	const { t, i18n } = useTranslation();

	const captchaRef = useRef(null);
	const [state, setState] = useState({});
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const RECAPTCHA_SECRET=process.env.REACT_APP_RECAPTCHA_SECRET;

	const PNF = require('google-libphonenumber').PhoneNumberFormat;
	const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

	const listI18nToGooglePhone = {
		"en": "US",
		"pt-BR": "BR",
		"es": "ES",
		"it": "IT",
		"": "US"
	}

	const handleKeyPress = (event) => {
    // Previne a entrada de caracteres não numéricos
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };


	const formatPhoneNumber = (phoneNumber) => { 
		const convertPhoneNUmber = String(phoneNumber);
		const language = i18n.language.length > 2 ? i18n.language.substr(3).toUpperCase() : listI18nToGooglePhone[i18n.language];
		if (convertPhoneNUmber.length > 4) {
			const number = phoneUtil.parseAndKeepRawInput(convertPhoneNUmber, language);
			return phoneUtil.format(number, PNF.INTERNATIONAL);
		}	
		return convertPhoneNUmber
		
	}

	const applyDateMask = (data) => {
		// document.getElementById('birthdate').addEventListener('input', function (e) {
			let value = data.replace(/\D/g, '');
			
			if (value.length > 8) value = value.slice(0, 8);
			
			if (value.length > 4) {
				value = value.replace(/^(\d{2})(\d{2})(\d{0,4}).*/, '$1/$2/$3');
			} else if (value.length > 2) {
				value = value.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
			}
			console.log(value);
			
			
			return value;
		// });
	}
	
	const login = () => {
		if (!state.name)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid name"),
			});
		if (!state.lastName)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid last name"),
			});
		if (!state.email)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid e-mail"),
			});
		if (!state.birthDay || !state.birthDay.match(/\d{2}\/\d{2}\/\d{4}/))
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid Birthday"),
			});
		if (!state.gender)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid Gender"),
			});
		if (!state.email_confirm)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, confirm your e-mail"),
			});
		if (state.email !== state.email_confirm)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Your e-mail is different from the confirmation email"),
			});
		if (!state.password)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, insert a valid password"),
			});

		if (!state.token)
			return Swal.fire({
				icon: "error",
				title: "Oops...",
				text: t("Please, check on captcha verification"),
			});

		setLoading(true);

		const { email_confirm, ...stateData } = state;

		axios
			.post(`${process.env.REACT_APP_API}/user/signup`, { 
				...stateData,
				text_reset_first: t("text_validation_first"),
				text_reset_second: t("text_validation_second"),
				text_reset_footer: t("text_validation_footer"),
				text_reset_subject: t("text_validation_subject"),
				text_reset_button: t("text_validation_button"), 
			}, {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.result) {
					// history.push("/payment");
					// history.push("/");
					window.location.href = "/signup-success";
				} else {
					setLoading(false);
					let error =
						t("Could not sign up at this time. Please, try again later.");
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				let error = t("Could not sign up at this time. Please, try again later.");
				if (e.response.status === 400 && e.response?.data?.error) {
					error = t(e.response.data.error);
				}
				Swal.fire({
					icon: "error",
					title: "Ooopsss!",
					text: error,
				});
			})
			captchaRef.current.reset();
	};

	return (
		<>
			<main className="login-wrapper">
				<div className="logo">
					<img src={LoginLogo} alt="Eyetesters" />
				</div>
				<div className="form">
					<div className="form-wrapper">
						<div className="form-inset">
							<h2>{t("Sign Up")}</h2>
							<span>{t("To get started, please, fill the form below")}</span>

							<div className="form-group">
								<label>{t("Your name")}</label>
								<input
									type="text"
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												name: value,
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>{t("Your last name")}</label>
								<input
									type="text"
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												lastName: value,
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>{t("Type your birthday")}</label>
								<input
									type="text"
									value={state.birthDay}
									id="birthdate"
									placeholder="dd/mm/aaaa"
									onChange={(e) => {
										let value = e.target.value;
										const dtFormat = applyDateMask(value);
										setState((state) => {
											return {
												...state,
												birthDay: dtFormat,
											};
										});
									}}
								/>
								
							</div>
							<div className="form-group">
								<label>{t("Gender")}</label>
								<select
									size={'lg'}
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												gender: value,
											};
										});
									}}
								>
									<option value="">{t("Select")}</option>
									<option value="Male">{t("Male")}</option>
									<option value="Female">{t("Female")}</option>
									<option value="Other">{t("Other")}</option>
								</select>
							</div>
							<div className="form-group">
								<label>{t("Mobile Number")}</label>
								<input
									type="text"
									maxLength={17}
									onKeyPress={handleKeyPress}
									onChange={(e) => {
										let value = e.target.value.slice(0, 18);
										setState((state) => {
											return {
												...state,
												mobileNumber: formatPhoneNumber(value),
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>Your e-mail</label>
								<input
									type="text"
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												email: value,
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>{t("Confirm your e-mail")}</label>
								<input
									type="text"
									onChange={(e) => {
										let value = e.target.value;
										setState((state) => {
											return {
												...state,
												email_confirm: value,
											};
										});
									}}
								/>
							</div>
							<div className="form-group">
								<label>{t("Your password")}</label>
								<input
									type="password"
									onChange={(e) => {
										setState((state) => {
											return {
												...state,
												password: e.target.value,
											};
										});
									}}
								/>
							</div>

							<div className="recatpcha">
								<ReCAPTCHA
									sitekey={RECAPTCHA_SECRET}
									ref={captchaRef}
									onChange={(token) => {
										setState((state) => {
											return {
												...state,
												token,
											};
										});
									}}
								/>
							</div>

							<div className="form-options">
								{!loading ? (
									<button onClick={login}>{t("Continue")}</button>
								) : (
									<Spinner />
								)}
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

export default App;
