import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Header from "../components/Header";
import ErrorView from "../components/Error";
import Footer from "../Footer";
import axios from "axios";
import { ReactComponent as Spinner } from "../assets/images/spinner.svg";
import dayjs from "dayjs";
import TestView from "../components/TestView";
import Modal from "../components/Modal";
import Subscribe from "../components/Subscribe";
import SubscribeTrial from "../components/SubscribeTrial";
import VerifyView from "../components/VerifyView.js";

function HomePage() {
	let history = useHistory();
	const { t, i18n } = useTranslation();

	const [status, setStatus] = useState("loading");
	const [state, setState] = useState(null);
	const [testData, setTestData] = useState(null);
	const [modal, setModal] = useState(false);
	const [modalTest, setModalTest] = useState(false);
	console.log('history->>', history);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API}/user/tests`, {
				withCredentials: true,
			})
			.then(function (res) {
				if (res.data.result) {
					setState(res.data);
					setStatus("done");
				} else {
					setStatus("error");
				}
			})
			.catch((e) => {
				setStatus("error");
			});
	}, []);
	console.log('state', state);
	return (
		<>
			<Header />

			<div className="content">
				<main className="user-page">
					<h1>{t("My Tests")}</h1>

					{status === "loading" ? (
						<div className="loading-view auto">
							<div className="spinner-wrapper">
								<Spinner />
							</div>
						</div>
					) : null}

					{state && state.pending > 0 && (<div style={{color: "#216e75", fontSize: "13px"}}>{t("**You have a test under review by specialists. Once completed, your test will be displayed in the list below.")}</div>)}

					{status === "done" ? (
						<div className="tests-listing">
							<Modal
								visible={modal}
								close={() => {
									setModal(false);
								}}
							>
								<TestView data={testData} />
							</Modal>
							<Modal
								visible={modalTest}
								close={() => {
									setModalTest(false);
								}}
							>
								<VerifyView data={testData} />
							</Modal>
							{state?.tests?.length && (state.status === "active" || state.status === "trialing") ? (
								<table>
									<thead>
										<tr>
											<th width={"200"}>{t("Taken at")}</th>
											<th>{t("Name")}</th>
											<th>{t("E-mail")}</th>
											<th>{t("Gender")}</th>
											<th width={"100"}>{t("Action")}</th>
										</tr>
									</thead>
									<tbody>
										{state.tests.map((item, index) => {
											return (
												<tr>
													<td>
														{dayjs(item.createdAt).format(
															"MM/DD/YYYY - HH:mm:ss"
														)}
													</td>
													<td>{item.name}</td>
													<td>{item.email}</td>
													<td>{t(item.gender)}</td>
													<td>
														<a
															href="#view"
															className="btn-view"
															onClick={(e) => {
																e.preventDefault();
																setTestData(item);
																setModal(true);
															}}
														>
															{t("View")}
														</a>
														{history?.location?.search === "?verify=true" && (
														<a
															href="#view"
															className="btn-view"
															onClick={(e) => {
																e.preventDefault();
																history.push({ pathname: "/verify", state: { data: item } });
															}}
														>
															{t("Verify")}
														</a>
														)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							) : null}

							{(state.tests?.length > 0 && !state.status) && state?.status !== "active" ? (
								<SubscribeTrial />
							) : null}
							{!state?.tests?.length && state.status === "active" && state.pending < 1 ? (
								<div className="no-tests">
									<h4>{t("You haven't taken any tests yet.")}</h4>
									<Link to="/start">{t("Start now")}</Link>
								</div>
							) : null}
							{!state?.tests?.length && !state.status ? (
								<SubscribeTrial />
							) : null}
						</div>
					) : null}

					{status === "error" && <ErrorView />}
				</main>
			</div>
			<Footer />
		</>
	);
}

export default HomePage;
